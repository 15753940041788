import "./Slider.css";
import { useTranslation } from "react-i18next";
import { Images } from "../../images";

export default function Slider() {
  const { t } = useTranslation();

  function importAll(r) {
    let imagess = {};
    r.keys().map((item) => {
      imagess[item.replace("./", "")] = r(item);
    });
    return imagess;
  }

  const imagess = importAll(
    require.context("../../images/gallery", false, /\.(jpg|JPG)$/)
  );
  var images_url = [];

  for (var i = 1; i < Object.keys(imagess).length + 1; i++) {
    if (imagess["gallery (" + i.toString() + ").jpg"] === undefined) {
      images_url.push(imagess["gallery (" + i.toString() + ").JPG"]);
    } else {
      images_url.push(imagess["gallery (" + i.toString() + ").jpg"]);
    }
  }

  // console.log(images_url);

  return (
    <>
      {/* <img src={imagess["gallery (6).jpg"]} alt="" /> */}
      <div className="slider">
        <h1>{t("slider_header")}</h1>
        <div className="slider_wrapper">
          <div className="slider_item">
            {images_url.map((image) => (
              <img src={image} alt="" />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
