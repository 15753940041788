import "./Header.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Header() {
  const { t } = useTranslation();
  return (
    <>
      <div className="header" id="header">
        <div className="container">
          <Link to="/">
            <img src="../images/logo.png" alt="elsallab logo" class="logo" />
          </Link>
          <ul className="main-nav">
            <li>
              <Link to="/">{t("navbar_home")}</Link>
            </li>
            <li>
              <Link to="/about-hospital">{t("navbar_about")}</Link>
            </li>
            <li>
              <a href="#">{t("navbar_gallery")}</a>
              <div className="mega-menu gallery-menu">
                <ul className="links">
                  <li>
                    <Link to="/gallery/hospital">
                      {t("gallery_navitem_general")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/gallery/rooms">
                      {t("gallery_navitem_rooms")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/gallery/or">{t("gallery_navitem_or")}</Link>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a href="#">{t("navbar_sections")}</a>
              <div className="mega-menu">
                <ul className="links">
                  <li>
                    <Link to="/sections/1">{t("sections_item_1")}</Link>
                  </li>
                  <li>
                    <Link to="/sections/2">{t("sections_item_2")}</Link>
                  </li>
                  <li>
                    <Link to="/sections/3">{t("sections_item_3")}</Link>
                  </li>
                  <li>
                    <Link to="/sections/4">{t("sections_item_4")}</Link>
                  </li>
                  <li>
                    <Link to="/sections/5">{t("sections_item_5")}</Link>
                  </li>
                  <li>
                    <Link to="/sections/6">{t("sections_item_6")}</Link>
                  </li>
                </ul>
                <ul className="links">
                  <li>
                    <Link to="/sections/7">{t("sections_item_7")}</Link>
                  </li>
                  <li>
                    <Link to="/sections/8">{t("sections_item_8")}</Link>
                  </li>
                  <li>
                    <Link to="/sections/9">{t("sections_item_9")}</Link>
                  </li>
                  <li>
                    <Link to="/sections/10">{t("sections_item_10")}</Link>
                  </li>
                  <li>
                    <Link to="/sections/11">{t("sections_item_11")}</Link>
                  </li>
                  <li>
                    <Link to="/sections/12">{t("sections_item_12")}</Link>
                  </li>
                  <li>
                    <Link to="/sections/13">{t("sections_item_13")}</Link>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a href="../#contact">{t("navbar_contact")}</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
