import "./About.css";
import { useTranslation } from "react-i18next";

export default function About() {
  const { t } = useTranslation();
  const items = t("about_us_par").split("\n");
  return (
    <>
      <div className="about" id="about">
        <div className="container">
          <h1>{t("about_us_heading")}</h1>
          {items.map((item) => (
            <p>{item}</p>
          ))}
        </div>
      </div>
    </>
  );
}
