import "./Companies.css";
import { CompaniesImages } from "../../images";
import { useTranslation } from "react-i18next";

export default function Companies() {
  const { t } = useTranslation();
  return (
    <>
      <div className="companies">
        {/* <h1>
          ترحب إدارة وأطباء المستشفى بعملاء شركات التأمين الطبية المتعاقدة مع
          المستشفى
        </h1> */}
        <h1>{t("companies_header")}</h1>
        <div className="companies_wrapper">
          <div className="companies_item">
            {CompaniesImages.map((image) => (
              <img src={image.src} alt="" />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
