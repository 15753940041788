import "./App.css";
import Home from "./pages/Home";
import GalleryPage from "./pages/GalleryPage";
import AboutPage from "./pages/AboutHospitalPage";
import SectionPage from "./pages/SectionPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-hospital" element={<AboutPage />} />
        <Route path="/gallery/:cat" element={<GalleryPage />} />
        <Route path="/sections/:cat" element={<SectionPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
