import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEn from "./local/en.json"
import translationAr from "./local/ar.json"


const resources = {
  en: {
    translation: translationEn
  },
  ar: {
    translation: translationAr
  }
};

i18n
  .use(initReactI18next) 
  .use(LanguageDetector)
  .init({
    resources,
    interpolation: {
      escapeValue: false 
    },
    react: {
        useSuspense: false
    },
    detection: {
        order: ['htmlTag']
    },
  });
  export default i18n;