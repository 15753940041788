import "./Topbar.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import CallIcon from "@mui/icons-material/Call";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";

export default function Topbar() {
  const { t, i18n } = useTranslation();
  i18n.on("languageChanged", (lng) => {
    document.documentElement.setAttribute("lang", lng);
  });
  const lanG = document.querySelector("html").lang;
  if (lanG === "ar") {
    document.querySelector("html").dir = "rtl";
  } else {
    document.querySelector("html").dir = "ltr";
  }
  return (
    <>
      <div className="topbar">
        <div className="container">
          <div className="social-icons">
            <a
              href="https://www.facebook.com/elsallabhospitals"
              target="_blank"
            >
              <FacebookIcon className="icon" />
            </a>
            <InstagramIcon className="icon" />
          </div>
          <div className="phones">
            <span>0502208559 | 0502208558 | 0502208557</span>
            <CallIcon className="phone-icon" />
          </div>
          <div className="language">
            <LanguageIcon className="language-icon" />
            <div className="languages-container">
              <a
                href="#"
                onClick={() => {
                  i18n.changeLanguage("ar");
                }}
              >
                اللغة اعربية
              </a>
              <a
                href="#"
                onClick={() => {
                  i18n.changeLanguage("en");
                }}
              >
                English
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
