import "./About_hospital.css";
import { useTranslation } from "react-i18next";
import VideoPlayer from "react-video-js-player";
import video from "../../images/about_video.mp4";

export default function About_hospital() {
  const { t } = useTranslation();
  return (
    <>
      <div className="about_hospital">
        <h1 className="about_hospital_header">{t("about_hospital_header")}</h1>
        <div className="about_box">
          <p>{t("about_hospital_par")}</p>
          <VideoPlayer
            src={video}
            width="720"
            height="420"
            className="about_video"
          />
        </div>
        <div className="about_box">
          <h2 className="about_hospital_header">{t("about_hospital_IC")}</h2>
          <p>{t("about_hospital_IC_1")}</p>
          <p>{t("about_hospital_IC_2")}</p>
          <p>{t("about_hospital_IC_3")}</p>
          <p>{t("about_hospital_IC_4")}</p>
          <div className="container">
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (72).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (73).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (74).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (75).JPG")} />
              </div>
            </div>
          </div>
        </div>

        <div className="about_box">
          <h2 className="about_hospital_header">{t("about_hospital_Hemo")}</h2>
          <p>{t("about_hospital_Hemo_par")}</p>
          <div className="container">
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (27).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (28).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (29).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (30).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (31).JPG")} />
              </div>
            </div>
          </div>
        </div>

        <div className="about_box">
          <h2 className="about_hospital_header">{t("about_hospital_OR")}</h2>
          <p>{t("about_hospital_OR_par")}</p>
          <div className="container">
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (1).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (2).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (3).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (4).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (5).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (6).jpg")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (7).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (8).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (9).JPG")} />
              </div>
            </div>
          </div>
        </div>
        <div className="about_box">
          <h2 className="about_hospital_header">
            {t("about_hospital_learning")}
          </h2>
          <p>{t("about_hospital_learning_par")}</p>
          <div className="container">
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (10).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (11).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (12).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (13).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (14).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (15).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (16).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (17).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (18).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (19).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (20).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (21).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (22).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (23).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (24).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (25).JPG")} />
              </div>
            </div>
            <div className="box">
              <div className="image">
                <img src={require("../../images/gallery/gallery (26).JPG")} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
