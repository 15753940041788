import Topbar from "../components/topbar/Topbar";
import Header from "../components/navbar/Header";
import Section from "../components/sections/Section";
import Footer from "../components/footer/Footer";

export default function GalleryPage() {
  return (
    <>
      <Topbar />
      <Header />
      <Section />
      <Footer />
    </>
  );
}
