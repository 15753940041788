import Topbar from "../components/topbar/Topbar";
import Header from "../components/navbar/Header";
import Landing from "../components/landing/Landing";
import About from "../components/about/About";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/Footer";
import Companies from "../components/companies/Companies";
import Slider from "../components/slider/Slider";

const Home = () => {
  return (
    <>
      <Topbar />
      <Header />
      <Landing />
      <About />
      <Slider />
      <Contact />
      <Companies />
      <Footer />
    </>
  );
};

export default Home;
