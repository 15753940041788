import "./Section.css";
import { Link, useParams } from "react-router-dom";
import { SectionsImages } from "../../images";
import { useTranslation } from "react-i18next";

export default function Section() {
  const { t } = useTranslation();
  let param = useParams();
  const filteredImages = SectionsImages.filter(
    (image) => parseInt(image.id) === parseInt(param.cat)
  );
  return (
    <>
      <div className="section">
        <div className="container">
          {filteredImages.map((image) => (
            <div className="content">
              <h2 className="header-title">{t(image.header)}</h2>
              <div className="box" key={image.id}>
                <div className="image">
                  <img src={image.src} alt="" />
                </div>
              </div>
              {t(image.par)
                .split("\n")
                .map((item) => (
                  <p>{item}</p>
                ))}
              {parseInt(param.cat) === 2 ? (
                <>
                  <p style={{ margin: "30px 0" }}>{t("sections_par_2-2")}</p>
                  <div className="box" style={{ width: "30%" }}>
                    <div className="image">
                      <img src="../images/sections/section (2-2).jpg" alt="" />
                    </div>
                  </div>
                </>
              ) : null}

              {parseInt(param.cat) === 3 ? (
                <>
                  <p style={{ margin: "30px 0" }}>{t("sections_par_3-2")}</p>
                  <div className="box">
                    <div className="image">
                      <img src="../images/sections/section (3-2).jpg" alt="" />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
