import "./Footer.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Footer() {
  const { t } = useTranslation();
  function HomepageScroll(){
    window.scrollTo(0,0)
  }
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="box">
            <h3>{t("landing-header")}</h3>
            <ul className="social">
              <li>
                <a href="https://www.facebook.com/elsallabhospitals/" target='_blank' className="facebook">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/invites/contact/?i=l1blnpwgd4d&utm_content=nbq8ew4" target="_blank" className="instagram">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="box">
            <ul className="links">
              <li>
                <Link to="/" onClick={HomepageScroll}>{t("navbar_home")}</Link>
              </li>
              <li>
                <Link to="/about-hospital">{t("navbar_about")}</Link>
              </li>
              <li>
                <Link to="/gallery/hospital">{t("navbar_gallery")}</Link>
              </li>
              <li>
                <Link to="/" onClick={HomepageScroll}>{t("navbar_sections")}</Link>
              </li>
              <li>
                <a href="../#contact">{t("navbar_contact")}</a>
              </li>
            </ul>
          </div>
          <div className="box">
            <div className="line">
              <i className="fas fa-map-marker-alt fa-fw"></i>
              <div className="info">{t("footer_address")}</div>
            </div>
            <div className="line">
              <i className="far fa-envelope fa-fw"></i>
              <div className="info">info@elsallabhospitals.com</div>
            </div>
            <div className="line">
              <i className="fas fa-phone-volume fa-fw"></i>
              <div className="info">
                <span>{t("footer_num_1")}</span>
                <span>{t("footer_num_2")}</span>
                <span>{t("footer_num_3")}</span>
              </div>
            </div>
          </div>
        </div>
        <p class="copyright">{t("footer_credits")}</p>
      </div>
    </>
  );
}
