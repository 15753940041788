import Topbar from "../components/topbar/Topbar";
import Header from "../components/navbar/Header";
import Gallery from "../components/gallery/Gallery";
import Footer from "../components/footer/Footer";

export default function GalleryPage() {
  return (
    <>
      <Topbar />
      <Header />
      <Gallery />
      <Footer />
    </>
  );
}
