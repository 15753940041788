import "./Landing.css";
import { useTranslation } from "react-i18next";

export default function Landing() {
  const { t } = useTranslation();
  return (
    <>
      <div className="landing">
        <div className="text">
          <h1>{t("landing-header")}</h1>
          <h3>{t("landing-sub-header")}</h3>
          <p>{t("landing-paragraph")}</p>
        </div>

        <div className="image">
          <div class="overlay"></div>
          <img src="../images/gallery (3).JPG" alt="elsallab hospital landing image" />
        </div>
        <button>
          <a href="#contact">{t("contact_button")}</a>
        </button>
      </div>
    </>
  );
}
