import "./Contact.css";
import { useTranslation } from "react-i18next";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { useState } from "react";

export default function Contact() {
  const { t } = useTranslation();
  const [isActive, setActive] = useState(false);
  const handleToggle = () => {
    setActive(!isActive);
  };
  console.log(isActive);
  return (
    <>
      <div className="contact" id="contact">
        <div className="image-modal" id={isActive ? "toggleActive" : null}>
          <div class="modal-content">
            <span class="close" onClick={handleToggle}>
              &times;
            </span>
            <img src="../images/Map.jpg" alt="elsallab logo" class="logo" />
          </div>
        </div>
        <div className="map">
          <div className="map-wrapper">
            <iframe
              src="https://maps.google.com/maps?q=%D9%85%D8%B3%D8%AA%D8%B4%D9%81%D9%89%20%D8%A7%D9%84%D8%AC%D8%B2%D9%8A%D8%B1%D8%A9%20%D8%A7%D9%84%D8%AF%D9%88%D9%84%D9%89%20-%20%D8%A7%D9%84%D8%B3%D9%84%D8%A7%D8%A8%D8%8C%20Al%20Doctor%20Atef%20Abd%20Al%20Menagi,%20El%20Mansoura%201,%20Egypt&t=&z=19&ie=UTF8&iwloc=&output=embed"
              width="100%"
              height="100%"
              frameBorder=""
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
          </div>
          <a href="#contact" className="directions" onClick={handleToggle}>
            {t("contact_get_dir_btn")}
          </a>
        </div>

        <div className="form">
          <div className="form-wrapper">
            <h1>{t("contact_heading")}</h1>
            <form
              action="https://formspree.io/f/mayvkpap"
              method="POST"
            >              <input
                class="input"
                type="text"
                placeholder={t("contact_form_name")}
                name="name"
              />
              <input
                class="input"
                type="email"
                placeholder={t("contact_form_mail")}
                name="email"
              />
              <input
                class="input"
                type="text"
                placeholder={t("contact_form_phone")}
                name="phone"
              />
              <textarea
                class="input"
                placeholder={t("contact_form_message")}
                name="message"
              ></textarea>
              <input type="submit" value={t("contact_form_send_button")} />
            </form>
          </div>
        </div>
        {/* <MessengerCustomerChat
                    pageId="1523267071317188"
                    appId="626923762066765"
                /> */}
      </div>
    </>
  );
}
