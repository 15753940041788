import "./Gallery.css";
import { Link, useParams } from "react-router-dom";
import { Images } from "../../images";
import { useTranslation } from "react-i18next";

export default function Gallery() {
  const { t } = useTranslation();
  let param = useParams();

  function importAll(r) {
    let imagess = {};
    r.keys().map((item) => {
      imagess[item.replace("./", "")] = r(item);
    });
    return imagess;
  }

  const imagess = importAll(
    require.context("../../images/gallery", false, /\.(jpg|JPG)$/)
  );
  var images_url = [];

  for (var i = 1; i < Object.keys(imagess).length + 1; i++) {
    if (imagess["gallery (" + i.toString() + ").jpg"] === undefined) {
      images_url.push(imagess["gallery (" + i.toString() + ").JPG"]);
    } else {
      images_url.push(imagess["gallery (" + i.toString() + ").jpg"]);
    }
  }

  const filteredImages = Images.filter((image) => image.type === param.cat);
  console.log(filteredImages);
  let headerTitle;
  switch (param.cat) {
    case "hospital":
      headerTitle = t("gallery_navitem_general");
      break;
    case "rooms":
      headerTitle = t("gallery_navitem_rooms");
      break;
    case "or":
      headerTitle = t("gallery_navitem_or");
      break;
  }

  const filter = () => {
    console.log("filter");
  };
  return (
    <>
      <div className="gallery" id="gallery">
        <h2 className="header-title">{headerTitle}</h2>
        <div className="gallery-header">
          {param.cat === "hospital" ? (
            <div className="container">
              {images_url.map((image) => (
                <div className="box" key={image.id}>
                  <div className="image">
                    <img src={image} alt="" />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="container">
              {filteredImages.map((image) => (
                <>
                  <div className="box" key={image.id}>
                    <div className="image">
                      <img src={image.src} alt="" />
                    </div>
                    {param.cat === "rooms" ? <h2>{t(image.name)}</h2> : null}
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
