export const Images = [
  {
    id: 1,
    src: "../../images/gallery/room (1).JPG",
    type: "rooms",
    name: "room-1",
  },
  {
    id: 2,
    src: "../../images/gallery/room (2).jpeg",
    type: "rooms",
    name: "room-2",
  },
  {
    id: 3,
    src: "../../images/gallery/room (3).jpeg",
    type: "rooms",
    name: "room-3",
  },
  {
    id: 4,
    src: "../images/gallery/or (1).jpg",
    type: "or",
  },
  {
    id: 5,
    src: "../images/gallery/or (2).jpg",
    type: "or",
  },
  {
    id: 6,
    src: "../images/gallery/or (3).jpg",
    type: "or",
  },
  {
    id: 7,
    src: "../images/gallery/or (4).jpg",
    type: "or",
  },
  {
    id: 8,
    src: "../images/gallery/or (5).jpg",
    type: "or",
  },
  {
    id: 9,
    src: "../images/gallery/or (6).jpg",
    type: "or",
  },
  {
    id: 10,
    src: "../images/gallery/or (7).jpg",
    type: "or",
  },
  {
    id: 11,
    src: "../images/gallery/or (8).jpg",
    type: "or",
  },
  {
    id: 12,
    src: "../images/gallery/or (9).jpg",
    type: "or",
  },
];

export const CompaniesImages = [
  {
    id: 1,
    src: "../images/companies/1.jpg",
  },
  {
    id: 2,
    src: "../images/companies/2.jpg",
  },
  {
    id: 2,
    src: "../images/companies/3.jpg",
  },
  {
    id: 2,
    src: "../images/companies/4.png",
  },
  {
    id: 2,
    src: "../images/companies/5.jpg",
  },
  {
    id: 2,
    src: "../images/companies/6.jpg",
  },
  {
    id: 2,
    src: "../images/companies/7.jpg",
  },
  {
    id: 2,
    src: "../images/companies/8.jpg",
  },
  {
    id: 2,
    src: "../images/companies/9.png",
  },
  {
    id: 2,
    src: "../images/companies/10.png",
  },
  {
    id: 2,
    src: "../images/companies/11.jpg",
  },
  {
    id: 2,
    src: "../images/companies/12.jpg",
  },
  {
    id: 2,
    src: "../images/companies/13.jpg",
  },
  {
    id: 2,
    src: "../images/companies/14.jpg",
  },
  {
    id: 2,
    src: "../images/companies/15.jpg",
  },
  {
    id: 2,
    src: "../images/companies/16.jpg",
  },
  {
    id: 2,
    src: "../images/companies/17.jpg",
  },
  {
    id: 2,
    src: "../images/companies/18.jpg",
  },
  {
    id: 2,
    src: "../images/companies/19.png",
  },
  {
    id: 2,
    src: "../images/companies/20.png",
  },
  {
    id: 2,
    src: "../images/companies/21.png",
  },
  {
    id: 2,
    src: "../images/companies/22.png",
  },
  {
    id: 2,
    src: "../images/companies/23.jpg",
  },
  {
    id: 2,
    src: "../images/companies/24.png",
  },
  {
    id: 2,
    src: "../images/companies/25.png",
  },
];

export const SectionsImages = [
  {
    id: 1,
    src: "../images/sections/section (1).jpg",
    header: "sections_item_1",
    par: "sections_par_1",
  },
  {
    id: 2,
    src: "../images/sections/section (2).jpg",
    header: "sections_item_2",
    par: "sections_par_2",
  },
  {
    id: 3,
    src: "../images/sections/section (3).jpg",
    header: "sections_item_3",
    par: "sections_par_3",
  },
  {
    id: 4,
    src: "../images/sections/section (4).JPG",
    header: "sections_item_4",
    par: "sections_par_4",
  },
  {
    id: 5,
    src: "../images/sections/section (5).jpg",
    header: "sections_item_5",
    par: "sections_par_5",
  },
  {
    id: 6,
    src: "../images/sections/section (6).jpg",
    header: "sections_item_6",
    par: "sections_par_6",
  },
  {
    id: 7,
    src: "../images/sections/section (7).jpg",
    header: "sections_item_7",
    par: "sections_par_7",
  },
  {
    id: 8,
    src: "../images/sections/section (8).jpg",
    header: "sections_item_8",
    par: "sections_par_8",
  },
  {
    id: 9,
    src: "../images/sections/section (9).jpg",
    header: "sections_item_9",
    par: "sections_par_9",
  },
  {
    id: 10,
    src: "../images/sections/section (10).jpeg",
    header: "sections_item_10",
    par: "sections_par_10",
  },
  {
    id: 11,
    src: "../images/sections/section (11).jpg",
    header: "sections_item_11",
    par: "sections_par_11",
  },
  {
    id: 12,
    src: "../images/sections/section (12).jpg",
    header: "sections_item_12",
    par: "sections_par_12",
  },
  {
    id: 13,
    src: "../images/sections/section (13).jpg",
    header: "sections_item_13",
    par: "sections_par_13",
  },
];
